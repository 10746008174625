<template>
  <TemplateHeader :header="'Riwayat Penjualan'" :withBackButton="true" :to="'/dashboard'">
    <TableSold :API="'/sold-product'" :allData="true" />
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../../layouts/TemplateHeader.vue";
import TableSold from "../../../components/Table/TableSold.vue";

export default {
  components: {
    TemplateHeader,
    TableSold,
  },
};
</script>
