<template>
  <TemplateHeader :header="'Riwayat Penjualan Berlian'" :withBackButton="true" :to="'/dashboard'">
    <TableSold :API="'/sold-product'" :diamondData="true" />
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../../layouts/TemplateHeader.vue";
import TableSold from "../../../components/Table/TableSoldBerlian.vue";

export default {
  components: {
    TemplateHeader,
    TableSold,
  },
};
</script>
