<template>
  <v-card>
    <v-card-title class="text-h6"> {{ title }} </v-card-title>
    <v-divider></v-divider>

    <validation-observer v-if="NEW" ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="submit">
        <v-card-title>
          <v-row>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" name="Nama Cabang" rules="required">
                <v-text-field
                  label="Nama Cabang"
                  :error-messages="errors"
                  v-model="newCabang.name"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" name="Nama Telpon" rules="required">
                <v-text-field
                  label="Nomor Telpon Cabang"
                  v-model="newCabang.telpon"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" name="Nama User" rules="required">
                <v-text-field
                  label="Nama User"
                  v-model="newCabang.name_user"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" name="Email User" rules="required">
                <v-text-field
                  label="Email User"
                  v-model="newCabang.email_user"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" name="Nama Role Cabang" rules="required">
                <v-text-field
                  label="Nama Role Cabang"
                  v-model="newCabang.role"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" name="Password" rules="required">
                <v-text-field
                  label="Password"
                  v-model="newCabang.password_user"
                  :error-messages="errors"
                  :type="showPass ? 'text' : 'password'"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPass = !showPass"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider v-slot="{ errors }" name="Alamat Cabang" rules="required">
                <v-text-field
                  label="Alamat Cabang"
                  v-model="newCabang.alamat"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" class="btn" text @click="closeModal"> Cancel </v-btn>
          <v-btn
            color="myPrimary white--text"
            class="btn"
            :disabled="invalid"
            @click="registerCabang"
          >
            Tambah
          </v-btn>
        </v-card-actions>
      </form>
    </validation-observer>

    <validation-observer v-if="EDIT" ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="submit">
        <v-card-title>
          <v-row>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" name="Nama Cabang" rules="required">
                <v-text-field
                  label="Nama Cabang"
                  :error-messages="errors"
                  v-model="data.branch_name"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" name="Nama Telpon" rules="required">
                <v-text-field
                  label="Nomor Telpon Cabang"
                  v-model="data.branch_phone"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" name="Nama User" rules="required">
                <v-text-field
                  label="Nama User"
                  v-model="data.user_name"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" name="Email User" rules="required">
                <v-text-field
                  label="Email User"
                  v-model="data.email"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" name="Nama Role Cabang" rules="required">
                <v-text-field
                  label="Nama Role Cabang"
                  v-model="data.role_name"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" name="Password" rules="required">
                <v-text-field
                  label="Password"
                  v-model="data.password"
                  :error-messages="errors"
                  :type="showPass ? 'text' : 'password'"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPass = !showPass"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider v-slot="{ errors }" name="Alamat Cabang" rules="required">
                <v-text-field
                  label="Alamat Cabang"
                  v-model="data.branch_address"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" class="btn" text @click="closeModal"> Cancel </v-btn>
          <v-btn color="myPrimary white--text" class="btn" :disabled="invalid" @click="edit_Cabang">
            Tambah
          </v-btn>
        </v-card-actions>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider, setInteractionMode, ValidationObserver } from "vee-validate";

import dataService from "../../services/data.service";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  props: ["title", "NEW", "EDIT", "data"],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      showPass: false,

      newCabang: {
        name: "",
        alamat: "",
        role: "",
        telpon: "",
        name_user: "",
        email_user: "",
        password_user: "",
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },

    async registerCabang() {
      const data = {
        branch_name: this.newCabang.name,
        branch_address: this.newCabang.alamat,
        branch_phone: this.newCabang.telpon,
        role_name: this.newCabang.role,
        user_name: this.newCabang.name_user,
        email: this.newCabang.email_user,
        password: this.newCabang.password_user,
      };

      await dataService
        .postNewCabang(data)
        .then(() => {
          location.reload();
        })
        .catch((err) => console.log(err));
    },

    async edit_Cabang() {
      const id = this.data.branch_id;

      const data = {
        branch_name: this.data.branch_name,
        branch_address: this.data.branch_address,
        branch_phone: this.data.branch_phone,
        role_name: this.data.role_name,
        user_name: this.data.user_name,
        email: this.data.email,
        password: this.data.password,
      };

      await dataService.updateCabang(id, data).then(() => {
        location.reload();
      });
    },
  },
};
</script>
