<template>
  <TemplateHeader
    :header="'Riwayat Penjualan'"
    :withBackButton="true"
    :to="`/dashboard/${id}`"
    :branch_name="branch_name"
  >
    <TableSold :API="`/inventory-sub-branch/sold-product/${id}`" :allData="true" />
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../../layouts/TemplateHeader.vue";
import TableSold from "../../../components/Table/TableSold.vue";

import dataService from "../../../services/data.service";

export default {
  components: {
    TemplateHeader,
    TableSold,
  },
  data() {
    return {
      dataCabang: [],
      branch_name: "",
      id: this.$route.params.id,
    };
  },
  methods: {
    async getSubCabangDetail() {
      const id = this.$route.params.id;

      await dataService.getSubCabangDetail(id).then((res) => {
        const findCabang = res.data.data;

        this.branch_name = findCabang.sub_branch_name;
        this.cabangLoading = false;
      });
    },
  },
  created() {
    this.getSubCabangDetail();
  },
};
</script>
