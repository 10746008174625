export default {
  state: {
    AUTH: false,
  },
  getters: {
    AUTH: (state) => state.AUTH,
  },
  actions: {
    SET_AUTH({ commit }, payload) {
      commit("SET_AUTH", payload);
    },
  },
  mutations: {
    SET_AUTH(state, payload) {
      state.AUTH = payload;
    },
  },
};
