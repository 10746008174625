<template>
  <div class="myHeight ma-0 myBrokenWhite pa-4">
    <div class="d-flex justify-space-between">
      <div class="text-h5">List Cabang</div>
    </div>
    <v-divider class="my-4"></v-divider>
    <div>
      <TableListCabang />
    </div>
    <v-divider class="my-4"></v-divider>
    <div class="d-flex justify-space-between">
      <div class="text-h5">List Sub Cabang</div>
    </div>
    <v-divider class="my-4"></v-divider>
    <div>
      <TableListSubCabang />
    </div>
  </div>
</template>

<script>
import TableListCabang from "../../components/Table/TableListCabang.vue";
import TableListSubCabang from "../../components/Table/TableListSubCabang.vue";

export default {
  components: {
    TableListCabang,
    TableListSubCabang,
  },
};
</script>

<style scoped>
.btn {
  text-transform: none;
}

.myHeight {
  min-height: 100vh;
}
</style>
