<template>
  <v-card>
    <v-row class="ma-4">
      <v-col cols="4">
        <v-text-field
          @change="searchItem"
          append-icon="mdi-magnify"
          label="Pencarian"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="3"></v-col>
      <v-col cols="3">
        <v-dialog v-model="dialogFilter" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block class="btn" v-bind="attrs" v-on="on"
              >Filter Berdasarkan Tanggal</v-btn
            >
          </template>

          <v-card>
            <v-card-title class="text-h5 lighten-2"> Date Filter </v-card-title>
            <v-divider></v-divider>

            <v-text-field
              v-model="dateRangeText"
              disabled
              readonly
              class="ma-4"
            ></v-text-field>

            <div class="text-center ma-4">
              <v-date-picker
                v-model="dates"
                range
                elevation="2"
                full-width
              ></v-date-picker>
            </div>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogFilter = false">
                Cancel
              </v-btn>
              <v-btn color="primary" @click="changeDate"> Filter </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="2">
        <v-btn block class="btn" @click="clearDate">Hapus Filter</v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headersSold"
      :items="dataSold"
      sort-by="date_order"
      item-key="rfid"
      class="elevation-1"
      :loading="tableLoading"
      @pagination="fetchDataProduct"
      loading-text="Loading... Please wait"
      :footer-props="footerProps"
      :server-items-length="dataPagination.itemsLength"
    >
      <template v-slot:top>
        <v-dialog v-model="dialogImage" max-width="550px">
          <ImageModal :data="showedData" @closePreview="closeImage" />
        </v-dialog>

        <v-dialog v-model="dialogPreviewGold" max-width="800px">
          <PreviewModal
            :data="showedData"
            @closePreview="closePreview"
            :dontShowButton="true"
            :emas="true"
          />
        </v-dialog>
        <v-dialog v-model="dialogEditStatus" max-width="800px">
          <v-card width="800">
            <v-card-title class="text-h6"> Edit Status Produk </v-card-title>

            <v-divider></v-divider>

            <v-select
              v-model="newStatus"
              label="Status"
              :items="selectStatus"
              item-text="label"
              item-value="value"
              class="px-4"
            >
            </v-select>

            <v-row v-if="newStatus === 'Terjual'" class="px-4">
              <v-col cols="6">
                <v-text-field
                  v-model="total_price"
                  :rules="[numberRule]"
                  prefix="Rp."
                  label="Total Harga"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="customer_name"
                  label="Nama Pelanggan"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-time-picker
                  v-model="newDate.time"
                  ampm-in-title
                  full-width
                ></v-time-picker>
              </v-col>
              <v-col cols="6">
                <v-date-picker
                  v-model="newDate.date"
                  full-width
                ></v-date-picker>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeEditStatus">
                TIDAK
              </v-btn>
              <v-btn color="green darken-1" text @click="confirmEditStatus">
                YA
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.photo_product`]="{ item }">
        <div class="p-2" @click="previewImage(item)">
          <v-img
            :src="item.photo_product"
            :alt="item.rfid"
            width="40px"
            height="40px"
            contain
          ></v-img>
        </div>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <div>{{ parseDate(item.created_at) }}</div>
      </template>
      <template v-slot:[`item.detail`]="{ item }">
        <v-btn x-small class="mr-2" @click="previewItem(item)"> Lihat </v-btn>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editStatusItem(item)">
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:[`item.total_price`]="{ item }">
        <div>{{ parseRupiah(item.total_price) }}</div>
      </template>
      <template v-slot:[`item.date_order`]="{ item }">
        {{ parseDate(item.date_order) }}
      </template>
      <template v-slot:[`item.price_gold`]="{ item }">
        <div>{{ parseRupiah(item.price_gold) }}</div>
      </template>

      <template v-slot:[`item.gold_profit`]="{ item }">
        <div>{{ parseGram(item.gold_profit) }}</div>
      </template>
      <template v-slot:[`body.append`]>
        <tr>
          <td colspan="12">
            <v-row class="pa-4" v-if="goldData">
              <v-col>Jumlah Emas Terjual : {{ gold_total }} gr</v-col>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import ImageModal from "../Modal/ImageModal.vue";
import PreviewModal from "../Modal/PreviewModal.vue";
import moment from "moment"
import dataService from "../../services/data.service";
export default {
  props: ["allData", "goldData", "diamondData", "branchID"],
  components: {
    ImageModal,
    PreviewModal,
  },
  data() {
    return {
      pagination: {
        itemsPerPage: 10,
        page: 1
      },
      dataPagination: {},
      tableLoading: false,

      dialogImage: false,

      dialogFilter: false,
      dates: ["", ""],
      dateFilter: [],

      dataSold: [],
      diamond_total: "",
      gold_total: "",

      dialogEditStatus: false,
      newStatus: "",
      newDate: {
        date: "",
        time: "",
      },
      customer_name: "",
      total_price: "",

      searchSold: "",
      headersSold: [
        { text: "Tanggal", value: "created_at", width: "200px" },
        { text: "Kode Barang", value: "sku", width: "100px" },
        { text: "RFID", value: "rfid", width: "120px" },
        { text: "Nama Produk", value: "product_name", width: "140px" },
        { text: "Gambar", value: "photo_product", width: "120px" },
        { text: "Berat", value: "weight", width: "120px" },
        { text: "Kadar", value: "purity", width: "120px" },
        { text: "Cabang", value: "branch_name", width: "130px" },
        { text: "Sub Cabang", value: "sub_branch_name", width: "220px" },
        { text: "Harga Modal", value: "price_gold", width: "120px" },
        { text: "Harga Total", value: "total_price", width: "120px" },
        { text: "Profit Emas", value: "gold_profit", width: "120px" },
        { text: "Detail", value: "detail", width: "95px" },
        {
          text: "Edit Status",
          value: "actions",
          width: "130px",
          align: "center",
        },
      ],

      showedData: {},

      editedDataSold: {},
      dialogPreviewGold: false,
      dialogPreviewBerlian: false,
      editedIndex: -1,

      selectStatus: [
        { value: "Tersedia", label: "Tersedia" },
        { value: "Terjual", label: "Terjual" },
      ],
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    footerProps() {
      let data = {
        "pagination": this.dataPagination,
        "items-per-page-options": [5, 10, 15, 20, 25],
      }
      return data
    }
  },
  watch: {
    dialogPreviewGold(val) {
      val || this.closePreview();
    },
    dialogPreviewBerlian(val) {
      val || this.closePreview();
    },
    dialogEditStatus(val) {
      val || this.closeEditStatus();
    },
  },
  methods: {
    numberRule(v) {
      if (v != undefined) {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0) return true;
        return "Number must greater than zero (0)";
      } else {
        return true
      }
    },
    parseRupiah(price) {
      if (price == null) {
        return "";
      } else {
        return (
          "Rp. " +
          price.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")
        );
      }
    },
    parseGram(weight) {
      if (weight == null) {
        return "";
      } else {
        return (
          weight + " gr"
        );
      }
    },
    parseDate(date){
      if (date) {
        return moment(String(date)).format("DD MMMM YYYY hh:mm");
      } else {
        return ""
      }
    },

    searchItem(v) {
      this.searchSold = v
      this.fetchDataProduct(this.pagination)
    },

    changeDate() {
      this.dateFilter = this.dates;

      this.fetchDataProduct(this.pagination);

      this.dialogFilter = false;
    },

    clearDate() {
      this.dateFilter = [];

      this.fetchDataProduct(this.pagination);
    },

    async fetchDataProduct(obj) {
      // tinggal nambah pengondisian branchID
      this.tableLoading = true;

      const params = {
        per_page: obj.itemsPerPage,
        page: obj.page,
      };

      if (this.dateFilter) {
        this.dateFilter[0] ? params["date_start"] = this.dateFilter[0] : null
        this.dateFilter[1] ? params["date_end"] = this.dateFilter[1] : null
        // params["date_start"] = "2022-10-19"
        // params["date_end"] = "2022-10-20"
      }

      if (this.searchSold) {
        params["search"] = this.searchSold
      }

      let pathParams = this.$route.params ? this.$route.params.id : ""

      await dataService.getCheckout("gold", pathParams, params)
        .then((res) => {
          this.dataPagination = {
            page: res.data.data.checkout.current_page,
            itemsPerPage: parseInt(res.data.data.checkout.per_page),
            pageStart: res.data.data.checkout.from-1,
            pageStop: res.data.data.checkout.to,
            pageCount: res.data.data.checkout.last_page,
            itemsLength: res.data.data.checkout.total,
          }

          this.dataSold = res.data.data.checkout.data
          // if (this.allData) {
          //   this.dataSold = res.data.data.data.data;
          // }

          // if (this.goldData) {
          //   this.dataSold = res.data.data.data.data.filter(
          //     (data) => data.stone_id === 1
          //   );
          // }

          // if (this.diamondData) {
          //   this.dataSold = res.data.data.data.data.filter(
          //     (data) => data.stone_id === 2
          //   );
          // }

          // this.diamond_total = res.data.data.diamond_total;
          this.gold_total = res.data.data.amount_of_gold_sold;
          this.tableLoading = false;
        });
    },

    previewItem(item) {
      this.editedIndex = this.dataSold.indexOf(item);
      this.showedData = Object.assign({}, item);
      this.dialogPreviewGold = true;
    },
    closePreview() {
      this.dialogPreviewGold = false;
      this.dialogPreviewBerlian = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    editStatusItem(item) {
      this.editedIndex = this.dataSold.indexOf(item);
      this.editedDataSold = Object.assign({}, item);

      this.dialogEditStatus = true;
    },
    closeEditStatus() {
      this.dialogEditStatus = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async confirmEditStatus() {
      const token = localStorage.getItem("token");

      const id = this.editedDataSold.id;

      const date = `${this.newDate.date} ${this.newDate.time}`;

      const data = {
        status: this.newStatus,
        date: date,
        customer_name: this.customer_name,
        total_price: this.total_price,
      };

      await axios
        .post(
          process.env.VUE_APP_BASE_URL + `/update-sold-products/${id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          location.reload();
        });
    },

    previewImage(item) {
      this.editedIndex = this.dataSold.indexOf(item);
      this.showedData = Object.assign({}, item);
      this.dialogImage = true;
    },
    closeImage() {
      this.dialogImage = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  created() {
    this.fetchDataProduct(this.pagination);
  },
};
</script>

<style scoped>
.btn {
  text-transform: none;
}

.myHeight {
  min-height: 100vh;
}

.myOverflow {
  overflow-y: scroll;
}
</style>
