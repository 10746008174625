<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">Import Data</span>
    </v-card-title>

    <v-divider></v-divider>

    <div class="px-4">
      <div class="mb-2">
        <v-file-input
          truncate-length="15"
          label="Input File"
          accept=".xlsx"
          v-model="excel"
        ></v-file-input>
      </div>
      <div v-if="GOLD || DIAMOND" class="mb-2">
        <v-file-input label="Foto" chips multiple accept="image/*" v-model="images"></v-file-input>
      </div>
    </div>

    <v-spacer></v-spacer>

    <div class="mx-4 py-2 px-8 text-caption myRed white--text" v-if="isError">
      {{ isError }}
    </div>

    <v-card-actions class="d-flex justify-end px-4">
      <v-btn color="myPrimary white--text" outlined @click="closeImporData" :disabled="buttonState">
        Cancel
      </v-btn>

      <v-btn
        v-if="RFID"
        color="myPrimary white--text"
        @click="saveImporDataRFID"
        :disabled="buttonState"
        :loading="buttonState"
      >
        Save
      </v-btn>

      <v-btn
        v-if="GOLD"
        color="myPrimary white--text"
        @click="saveImporDataGold"
        :disabled="buttonState"
      >
        Save
      </v-btn>

      <v-btn
        v-if="DIAMOND"
        color="myPrimary white--text"
        @click="saveImporDataDiamond"
        :disabled="buttonState"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import dataService from "../../services/data.service";

export default {
  props: ["RFID", "DIAMOND", "GOLD"],
  data() {
    return {
      excel: [],
      images: null,
      buttonState: false,
      isError: "",
    };
  },
  methods: {
    closeImporData() {
      this.$emit("closeImporData");
    },

    async saveImporDataRFID() {
      this.buttonState = true;

      const data = new FormData();

      data.append("file", this.excel);

      await dataService
        .importRFID(data)
        .then(() => {
          this.closeImporData();
          this.excel = [];
          this.images = [];
          this.isError = "";
          location.reload();
        })
        .catch(() => {
          this.isError = "Something went wrong, please try again later.";
          this.buttonState = false;
        });
    },

    async saveImporDataGold() {
      this.buttonState = true;

      const data = new FormData();

      for (let image of this.images) {
        data.append("images[]", image, image.name);
      }

      data.append("file", this.excel);

      if (this.excel !== null && this.images !== null) {
        await dataService
          .importGold(data)
          .then(() => {
            this.closeImporData();
            this.excel = [];
            this.images = [];
            this.isError = "";
            this.buttonState = false;
            location.reload();
          })
          .catch(() => {
            this.isError = "Something went wrong, please try again later.";
            this.buttonState = false;
          });
      } else {
        this.buttonState = false;
        this.isError = "Something went wrong, please try again later.";
      }
    },

    async saveImporDataDiamond() {
      this.buttonState = true;

      const data = new FormData();

      data.append("file", this.excel);
      for (let image of this.images) {
        data.append("images[]", image, image.name);
      }

      if (this.excel !== null && this.images !== null) {
        await dataService
          .importDiamond(data)
          .then(() => {
            this.closeImporData();
            this.excel = [];
            this.images = [];
            this.isError = "";
            this.buttonState = false;
            location.reload();
          })
          .catch(() => {
            this.isError = "Something went wrong, please try again later.";
            this.buttonState = false;
          });
      } else {
        this.buttonState = false;
        this.isError = "Something went wrong, please try again later.";
      }
    },
  },
};
</script>
