<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">Tambah Produk Berlian</span>
    </v-card-title>

    <v-divider></v-divider>

    <validation-observer ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="submit">
        <v-container>
          <v-row class="pa-4">
            <v-col cols="6" sm="3">
              <validation-provider v-slot="{ errors }" name="Nama Produk" rules="required">
                <v-text-field
                  v-model="newDataBerlian.name"
                  label="Nama Produk"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3">
              <validation-provider v-slot="{ errors }" name="Kode Barang" rules="required">
                <v-text-field
                  v-model="newDataBerlian.sku"
                  label="Kode Barang"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3">
              <validation-provider v-slot="{ errors }" name="Kode Harga" rules="required">
                <v-text-field
                  v-model="newDataBerlian.nomordesain"
                  label="Kode Harga"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3">
              <validation-provider v-slot="{ errors }" name="Harga Modal" rules="required">
                <v-text-field
                  v-model="newDataBerlian.harga_modal"
                  label="Harga Modal"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3">
              <validation-provider v-slot="{ errors }" name="Berat" rules="required">
                <v-text-field
                  v-model="newDataBerlian.berat"
                  label="Berat"
                  type="number"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3">
              <!-- <validation-provider v-slot="{ errors }" name="Kadar" rules="required"> -->
              <v-text-field
                v-model="newDataBerlian.kadar"
                label="Kadar"
                type="number"
              ></v-text-field>
              <!-- </validation-provider> -->
            </v-col>
            <v-col cols="6" sm="3">
              <!-- <validation-provider v-slot="{ errors }" name="Harga" rules="required"> -->
              <v-text-field
                v-model="newDataBerlian.harga"
                label="Harga Berlian Terikat"
                type="number"
              ></v-text-field>
              <!-- </validation-provider> -->
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="newDataBerlian.jb1"
                label="Jumlah Berlian 1"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field v-model="newDataBerlian.bb1" label="Ukuran Berlian 1"></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="newDataBerlian.jb2"
                label="Jumlah Berlian 2"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field v-model="newDataBerlian.bb2" label="Ukuran Berlian 2"></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="newDataBerlian.jb3"
                label="Jumlah Berlian 3"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field v-model="newDataBerlian.bb3" label="Ukuran Berlian 3"></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="newDataBerlian.jb4"
                label="Jumlah Berlian 4"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field v-model="newDataBerlian.bb4" label="Ukuran Berlian 4"></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="newDataBerlian.jb5"
                label="Jumlah Berlian 5"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field v-model="newDataBerlian.bb5" label="Ukuran Berlian 5"></v-text-field>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" name="Cabang" rules="required">
                <v-select
                  v-model="newDataBerlian.cabang"
                  label="Cabang"
                  :items="selectCabang"
                  item-text="branch_name"
                  item-value="id"
                  :error-messages="errors"
                  @input="getSubCabang"
                >
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3">
              <validation-provider v-slot="{ errors }" name="Sub Cabang">
                <v-select
                  v-model="newDataBerlian.subcabang"
                  label="Sub Cabang"
                  :items="selectSubCabang"
                  item-text="sub_branch_name"
                  item-value="id"
                  :error-messages="errors"
                  :disabled="!newDataBerlian.cabang"
                >
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3">
              <validation-provider v-slot="{ errors }" name="Status" rules="required">
                <v-select
                  v-model="newDataBerlian.status"
                  label="Status"
                  :items="selectStatus"
                  item-text="label"
                  item-value="value"
                  :error-messages="errors"
                >
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field v-model="newDataBerlian.catatan" label="Catatan"></v-text-field>
            </v-col>
            <v-col cols="12" class="d-flex flex-column align-center">
              <v-card class="mt-2 pa-6" max-width="600" v-if="selectedFile" elevation="0">
                <div class="pt-2 py-4 text-center">
                  <img :src="fileImage" width="250" />
                </div>
              </v-card>

              <v-card class="mt-2 pa-6" width="600" elevation="0">
                <v-btn
                  block
                  color="myPrimary"
                  outlined
                  depressed
                  :loading="isSelecting"
                  @click="onButtonClick"
                >
                  Unggah Foto
                </v-btn>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="onFileChanged"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions class="d-flex justify-end px-4">
          <v-spacer></v-spacer>
          <v-btn class="myPrimary white--text" outlined @click="closeNewDataBerlian" width="200">
            Cancel
          </v-btn>
          <v-btn
            class="myPrimary white--text"
            @click="saveNewDataBerlian"
            :disabled="invalid"
            width="200"
          >
            Save
          </v-btn>
        </v-card-actions>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";

import dataService from "../../services/data.service";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      selectCabang: [],
      selectSubCabang: [],

      selectedFile: "",
      fileImage: "",
      isSelecting: false,

      newDataBerlian: {
        name: "",
        sku: "",
        nomordesain: "",
        berat: "",
        kadar: "",
        harga: "",
        image: "",
        catatan: "",
        cabang: "",
        subcabang:"",
        status: "",
        jb1: "",
        bb1: "",
        jb2: "",
        bb2: "",
        jb3: "",
        bb3: "",
        jb4: "",
        bb4: "",
        jb5: "",
        bb5: "",
      },

      selectStatus: [
        { value: "tersedia", label: "Tersedia" },
        { value: "terjual", label: "Terjual" },
        { value: "perbaikan", label: "Perbaikan" },
        { value: "hilang", label: "Hilang" },
      ],
    };
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      this.fileImage = URL.createObjectURL(this.selectedFile);

      if (e.target.files.length === 0) {
        this.fileImage = URL.createObjectURL(null);
      }
    },
    async getCabang() {
      await dataService.getCabang().then((res) => {
        this.selectCabang = res.data;
      });
    },
    async getSubCabang() {
      await dataService.getAllSubCabang().then((res) => {
        const data = res.data.data;
        const value = data.filter((dt) => dt.branch_id == this.newDataBerlian.cabang);

        this.selectSubCabang = [{ sub_branch_name: "None", id: null }, ...value];
      });
    },
    async postNewBerlian() {
      const data = new FormData();

      data.append("name", this.newDataBerlian.name);
      data.append("sku", this.newDataBerlian.sku);
      data.append("design_number", this.newDataBerlian.nomordesain);
      data.append("harga_modal", this.newDataBerlian.harga_modal);
      data.append("berat", this.newDataBerlian.berat);
      data.append("kadar", this.newDataBerlian.kadar);
      data.append("price", this.newDataBerlian.harga);
      data.append("cabang_id", this.newDataBerlian.cabang);
      if (this.newDataBerlian.subcabang != "") {
        data.append("sub_cabang_id", this.newDataBerlian.subcabang);
      }
      data.append("catatan", this.newDataBerlian.catatan);
      data.append("status", this.newDataBerlian.status);
      data.append("diamond_weight1", this.newDataBerlian.bb1);
      data.append("diamond_weight2", this.newDataBerlian.bb2);
      data.append("diamond_weight3", this.newDataBerlian.bb3);
      data.append("diamond_weight4", this.newDataBerlian.bb4);
      data.append("diamond_weight5", this.newDataBerlian.bb5);
      data.append("diamond_quantity1", this.newDataBerlian.jb1);
      data.append("diamond_quantity2", this.newDataBerlian.jb2);
      data.append("diamond_quantity3", this.newDataBerlian.jb3);
      data.append("diamond_quantity4", this.newDataBerlian.jb4);
      data.append("diamond_quantity5", this.newDataBerlian.jb5);
      data.append("image", this.selectedFile);

      await dataService.postNewBerlian(data).then((res) => {
        console.log(res.status)
        location.reload();
      });
    },
    closeNewDataBerlian() {
      this.$emit("closeNewDataBerlian");
    },
    saveNewDataBerlian() {
      this.postNewBerlian();
      this.closeNewDataBerlian();
    },
  },
  created() {
    this.getCabang();
    this.getSubCabang();
  },
};
</script>
