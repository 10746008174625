import api from "./apiToken";

class GoldPriceService {
  getGoldPrice() {
    return api.get("/market-price");
  }
  postGoldPrice(data) {
    return api.post("/market-price", data);
  }
  updateGoldPrice(data) {
    return api.put("/market-price", data);
  }
}

export default new GoldPriceService();
