import axios from "axios";

const fetchClient = () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.request.use((config) => {
    let token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  return instance;
};

export default fetchClient();
