<template>
  <TemplateHeader
    :header="'Riwayat Penjualan Emas'"
    :withBackButton="true"
    :to="`/dashboard/${id}`"
    :branch_name="branch_name"
  >
    <TableSold :branchID="id" :goldData="true" />
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../../layouts/TemplateHeader.vue";
import TableSold from "../../../components/Table/TableSoldEmas.vue";

import dataService from "../../../services/data.service";

export default {
  components: {
    TemplateHeader,
    TableSold,
  },
  data() {
    return {
      dataCabang: [],
      branch_name: "",
      id: this.$route.params.id,
    };
  },
  methods: {
    async getCabang() {
      this.cabangLoading = true;
      const id = this.$route.params.id;

      await dataService.getCabang().then((res) => {
        this.dataCabang = res.data;

        const findCabang = this.dataCabang.find((data) => data.id == id);

        this.branch_name = findCabang.branch_name;
        this.cabangLoading = false;
      });
    },
  },
  created() {
    this.getCabang();
  },
};
</script>
