<template>
  <TemplateHeader
    :header="'Berlian Tersedia'"
    :withBackButton="true"
    :to="`/dashboard/${id}`"
    :branch_name="branch_name"
  >
    <TableBerlian
      :tableLoading="tableLoading"
      :dataBerlian="dataBerlian"
      :headersBerlian="headersBerlian"
      :sortBy="dataBerlian.id"
      :tableEdit="true"
    />
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../../layouts/TemplateHeader.vue";
import TableBerlian from "../../../components/Table/TableBerlian.vue";

import { headersBerlian } from "../../../data/headersBerlian";
import dataService from "../../../services/data.service";

export default {
  components: {
    TemplateHeader,
    TableBerlian,
  },
  data() {
    return {
      tableLoading: false,

      dataBerlian: [],

      headersBerlian: headersBerlian,

      dataCabang: [],
      branch_name: "",
      id: this.$route.params.id,
    };
  },
  methods: {
    async getCabang() {
      this.cabangLoading = true;
      const id = this.$route.params.id;

      await dataService.getCabang().then((res) => {
        this.dataCabang = res.data;

        const findCabang = this.dataCabang.find((data) => data.id == id);

        this.branch_name = findCabang.branch_name;
        this.cabangLoading = false;
      });
    },
    async getProductsDiamond() {
      this.tableLoading = true;
      const id = this.$route.params.id;

      await dataService.getProductsBranchDiamond(id).then((res) => {
        this.dataBerlian = res.data.data.filter(
          (data) =>
            data.status === "Tersedia" ||
            data.status === "Belum Terpindai" ||
            data.status === "Pindah Cabang" ||
            data.status === "Perbaikan"
        );

        this.tableLoading = false;
      });
    },
  },
  created() {
    this.getProductsDiamond();
    this.getCabang();
  },
};
</script>

<style scoped>
.myHeight {
  min-height: 100vh;
}
</style>
