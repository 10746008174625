<template>
  <TemplateHeader :header="'Riwayat Penjualan Emas'" :withBackButton="true" :to="'/dashboard'">
    <TableSold :API="'/sold-product'" :goldData="true" />
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../../layouts/TemplateHeader.vue";
import TableSold from "../../../components/Table/TableSoldEmas.vue";

export default {
  components: {
    TemplateHeader,
    TableSold,
  },
};
</script>
