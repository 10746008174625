import api from "./api";
import apiToken from "./apiToken";

class UserService {
  login(data) {
    return api.post("/central-login", data);
  }
  changePassword(data) {
    return apiToken.post("/reset-password", data);
  }
}

export default new UserService();
