<template>
  <TemplateHeader :header="'Emas Tersedia'" :withBackButton="true" :to="'/dashboard'">
    <TableEmas
      :tableLoading="tableLoading"
      :dataEmas="dataEmas"
      :headersEmas="headersEmas"
      :sortBy="dataEmas.id"
      :tableEdit="true"
      :dataPagination="dataPaginationEmas"
      @paginate="getProductsGold"
    />
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../../layouts/TemplateHeader.vue";
import TableEmas from "../../../components/Table/TableEmas.vue";

import { headersEmas } from "../../../data/headersEmas";

import dataService from "../../../services/data.service";

export default {
  components: {
    TemplateHeader,
    TableEmas,
  },
  data() {
    return {
      pagination: {
        per_page: 10,
        page: 1
      },
      dataPaginationEmas: {},
      tableLoading: false,

      dataEmas: [],

      headersEmas: headersEmas,
    };
  },
  methods: {
    async getProductsGold(params) {
      this.tableLoading = true;

      await dataService.getProductGold(params).then((res) => {
        this.dataEmas = res.data.data.filter(
          (data) =>
            data.status === "Tersedia" ||
            data.status === "Belum Terpindai" ||
            data.status === "Pindah Cabang" ||
            data.status === "Perbaikan"
        );
        this.dataPaginationEmas = {
          page: res.data.current_page,
          itemsPerPage: parseInt(res.data.per_page),
          pageStart: res.data.from-1,
          pageStop: res.data.to,
          pageCount: res.data.last_page,
          itemsLength: res.data.total,
        }
        this.tableLoading = false;
      });
    },
  },
  created() {
    this.getProductsGold(this.pagination);
  },
};
</script>

<style scoped>
.myHeight {
  min-height: 100vh;
}
</style>
