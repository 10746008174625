<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">Preview Image</span>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-img :src="data.photo_product" :alt="data.photo_product"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-spacer></v-spacer>
    <v-card-actions class="d-flex justify-end px-4">
      <v-btn color="myPrimary white--text" outlined @click="closePreview"> Tutup </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["data"],
  methods: {
    closePreview() {
      this.$emit("closePreview");
    },
  },
};
</script>
