import api from "./apiToken";

class VerificationService {
  getBeforeVerify() {
    return api.get("/getVerify");
  }
  getAfterVerify() {
    return api.get("/afterVerif");
  }
  postChangeVerify(id, data) {
    return api.post(`/changeVerify/${id}`, data);
  }
  postConfirmVerify(data) {
    return api.post(`/confirmProduct/`, data);
  }
}

export default new VerificationService();
