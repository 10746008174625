<template>
  <v-card>
    <v-card-title class="text-h5"> {{ text }}</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="dialogFalse"> TIDAK </v-btn>
      <v-btn color="green darken-1" text @click="dialogTrue"> YA </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["text"],
  methods: {
    dialogFalse() {
      this.$emit("dialogFalse");
    },
    dialogTrue() {
      this.$emit("dialogTrue");
    },
  },
};
</script>
