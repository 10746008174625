var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-divider'),(_vm.NEW)?_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Nama Cabang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nama Cabang","error-messages":errors},model:{value:(_vm.newCabang.name),callback:function ($$v) {_vm.$set(_vm.newCabang, "name", $$v)},expression:"newCabang.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Nama Telpon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nomor Telpon Cabang","error-messages":errors},model:{value:(_vm.newCabang.telpon),callback:function ($$v) {_vm.$set(_vm.newCabang, "telpon", $$v)},expression:"newCabang.telpon"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Nama User","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nama User","error-messages":errors},model:{value:(_vm.newCabang.name_user),callback:function ($$v) {_vm.$set(_vm.newCabang, "name_user", $$v)},expression:"newCabang.name_user"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Email User","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email User","error-messages":errors},model:{value:(_vm.newCabang.email_user),callback:function ($$v) {_vm.$set(_vm.newCabang, "email_user", $$v)},expression:"newCabang.email_user"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Nama Role Cabang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nama Role Cabang","error-messages":errors},model:{value:(_vm.newCabang.role),callback:function ($$v) {_vm.$set(_vm.newCabang, "role", $$v)},expression:"newCabang.role"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","error-messages":errors,"type":_vm.showPass ? 'text' : 'password',"append-icon":_vm.showPass ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(_vm.newCabang.password_user),callback:function ($$v) {_vm.$set(_vm.newCabang, "password_user", $$v)},expression:"newCabang.password_user"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Alamat Cabang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Alamat Cabang","error-messages":errors},model:{value:(_vm.newCabang.alamat),callback:function ($$v) {_vm.$set(_vm.newCabang, "alamat", $$v)},expression:"newCabang.alamat"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"btn",attrs:{"color":"red darken-1","text":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"btn",attrs:{"color":"myPrimary white--text","disabled":invalid},on:{"click":_vm.registerCabang}},[_vm._v(" Tambah ")])],1)],1)]}}],null,false,3170865151)}):_vm._e(),(_vm.EDIT)?_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Nama Cabang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nama Cabang","error-messages":errors},model:{value:(_vm.data.branch_name),callback:function ($$v) {_vm.$set(_vm.data, "branch_name", $$v)},expression:"data.branch_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Nama Telpon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nomor Telpon Cabang","error-messages":errors},model:{value:(_vm.data.branch_phone),callback:function ($$v) {_vm.$set(_vm.data, "branch_phone", $$v)},expression:"data.branch_phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Nama User","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nama User","error-messages":errors},model:{value:(_vm.data.user_name),callback:function ($$v) {_vm.$set(_vm.data, "user_name", $$v)},expression:"data.user_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Email User","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email User","error-messages":errors},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Nama Role Cabang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nama Role Cabang","error-messages":errors},model:{value:(_vm.data.role_name),callback:function ($$v) {_vm.$set(_vm.data, "role_name", $$v)},expression:"data.role_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","error-messages":errors,"type":_vm.showPass ? 'text' : 'password',"append-icon":_vm.showPass ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(_vm.data.password),callback:function ($$v) {_vm.$set(_vm.data, "password", $$v)},expression:"data.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Alamat Cabang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Alamat Cabang","error-messages":errors},model:{value:(_vm.data.branch_address),callback:function ($$v) {_vm.$set(_vm.data, "branch_address", $$v)},expression:"data.branch_address"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"btn",attrs:{"color":"red darken-1","text":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"btn",attrs:{"color":"myPrimary white--text","disabled":invalid},on:{"click":_vm.edit_Cabang}},[_vm._v(" Tambah ")])],1)],1)]}}],null,false,22476208)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }