<template>
  <v-card class="pa-4">
    <v-row>
      <v-col cols="6" class="">
        <div class="text-h6">Gambar Produk</div>
        <div class="py-4">
          <v-img
            :src="data.photo_product"
            :alt="data.rfid"
            max-width="500px"
            height="350px"
            class="rounded-lg"
          ></v-img>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="text-h6">Detail Produk</div>
        <div class="py-4">
          <v-card v-if="emas" color="#f8f8f8" elevation="0" rounded="lg" class="pa-4" height="350">
            <div class="d-flex justify-space-between">
              <div>RFID</div>
              <div>{{ data.rfid }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Kode Barang</div>
              <div>{{ data.sku }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Nama Produk</div>
              <div>{{ data.product_name }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Berat</div>
              <div>{{ data.weight }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Jenis</div>
              <div>{{ data.type }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Kadar</div>
              <div>{{ data.purity }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Kualitas</div>
              <div>{{ data.quality }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Kondisi</div>
              <div>{{ data.condition }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-card>
          <v-card
            v-if="berlian"
            color="#f8f8f8"
            elevation="0"
            rounded="lg"
            class="pa-4 myOverflow"
            height="350"
          >
            <div class="d-flex justify-space-between">
              <div>RFID</div>
              <div>{{ data.rfid }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Kode Barang</div>
              <div>{{ data.sku }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Nomor Desain</div>
              <div>{{ data.design_number }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Nama Produk</div>
              <div>{{ data.product_name }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Berat</div>
              <div>{{ data.weight }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Kadar</div>
              <div>{{ data.purity }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Harga</div>
              <div>{{ data.total_price ? parseRupiah(data.total_price) : parseRupiah(0) }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Ukuran Berlian 1</div>
              <div>{{ data.diamond_weight1 || 0 }} ct</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Jumlah Berlian 1</div>
              <div>{{ data.diamond_quantity1 || 0 }} pcs</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Ukuran Berlian 2</div>
              <div>{{ data.diamond_weight2 || 0 }} ct</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Jumlah Berlian 2</div>
              <div>{{ data.diamond_quantity2 || 0}} pcs</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Ukuran Berlian 3</div>
              <div>{{ data.diamond_weight3 || 0 }} ct</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Jumlah Berlian 3</div>
              <div>{{ data.diamond_quantity3 || 0 }} pcs</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Ukuran Berlian 4</div>
              <div>{{ data.diamond_weight4 || 0 }} ct</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Jumlah Berlian 4</div>
              <div>{{ data.diamond_quantity4 || 0 }} pcs</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Ukuran Berlian 5</div>
              <div>{{ data.diamond_weight5 || 0 }} ct</div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Jumlah Berlian 5</div>
              <div>{{ data.diamond_quantity5 || 0 }} pcs</div>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="mySecondary white--text" outlined text @click="closePreview">Close</v-btn>
      <v-btn v-show="!dontShowButton" class="myPrimary white--text" text @click="printItemConfirm"
        >Print</v-btn
      >
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["data", "dontShowButton", "emas", "berlian"],
  methods: {
    closePreview() {
      this.$emit("closePreview");
    },
    printItemConfirm() {
      this.$emit("printItemConfirm");
    },
    parseRupiah(price) {
      if (price == null) {
        return "";
      } else {
        return (
          "Rp. " +
          price.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")
        );
      }
    },
  },
};
</script>

<style scoped>
.myOverflow {
  overflow-y: scroll;
}
</style>
