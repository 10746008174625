<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">New Item</span>
    </v-card-title>

    <v-divider></v-divider>

    <validation-observer ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="submit">
        <v-container>
          <v-row class="pa-4">
            <v-col cols="12" sm="6">
              <validation-provider v-slot="{ errors }" name="Nama Produk" rules="required">
                <v-text-field
                  v-model="newDataEmas.name"
                  label="Nama Produk"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6">
              <validation-provider v-slot="{ errors }" name="Kode Barang" rules="required">
                <v-text-field
                  v-model="newDataEmas.sku"
                  label="Kode Barang"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6">
              <validation-provider v-slot="{ errors }" name="Berat" rules="required">
                <v-text-field
                  v-model="newDataEmas.berat"
                  label="Berat"
                  :error-messages="errors"
                  type="number"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="newDataEmas.jenis" label="Jenis"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="newDataEmas.kadar" label="Kadar" type="number"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="newDataEmas.kualitas" label="Kualitas"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="newDataEmas.cabang"
                label="Cabang"
                :items="selectCabang"
                item-text="branch_name"
                item-value="id"
                @input="getSubCabang"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="newDataEmas.subcabang"
                label="Sub Cabang"
                :items="selectSubCabang"
                item-text="sub_branch_name"
                item-value="id"
                :disabled="!newDataEmas.cabang"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="newDataEmas.kondisi"
                label="Kondisi"
                :items="selectKondisi"
                item-text="label"
                item-value="value"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="newDataEmas.status"
                label="Status"
                :items="selectStatus"
                item-text="label"
                item-value="value"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="newDataEmas.catatan" label="Catatan"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-card class="mt-2 pa-6" max-width="600" v-if="selectedFile" elevation="0">
                <div class="pt-2 py-4 text-center">
                  <img :src="fileImage" width="250" />
                </div>
              </v-card>

              <v-card class="mt-2 pa-6" width="600" elevation="0">
                <v-btn
                  block
                  color="myPrimary"
                  outlined
                  depressed
                  :loading="isSelecting"
                  @click="onButtonClick"
                >
                  Unggah Foto
                </v-btn>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="onFileChanged"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-spacer></v-spacer>

        <v-card-actions class="d-flex justify-end px-4">
          <v-btn class="myPrimary white--text" outlined @click="closeNewDataEmas"> Cancel </v-btn>
          <v-btn class="myPrimary white--text" @click="saveNewDataEmas" :disabled="invalid">
            Save
          </v-btn>
        </v-card-actions>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";

import dataService from "../../services/data.service";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      selectCabang: [],
      selectSubCabang: [],
      selectKondisi: [
        { value: "Baru", label: "Baru" },
        { value: "Bekas", label: "Bekas" },
      ],
      selectStatus: [
        { value: "tersedia", label: "Tersedia" },
        { value: "terjual", label: "Terjual" },
        { value: "perbaikan", label: "Perbaikan" },
        { value: "hilang", label: "Hilang" },
      ],

      selectedFile: "",
      fileImage: "",
      isSelecting: false,

      newDataEmas: {
        name: "",
        sku: "",
        berat: "",
        jenis: "",
        kadar: "",
        kualitas: "",
        kondisi: "",
        catatan: "",
        cabang: "",
        subcabang: "",
        status: "",
      },
    };
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      this.fileImage = URL.createObjectURL(this.selectedFile);

      if (e.target.files.length === 0) {
        this.fileImage = URL.createObjectURL(null);
      }
    },
    async getCabang() {
      await dataService.getCabang().then((res) => {
        this.selectCabang = res.data;
      });
    },
    async getSubCabang() {
      await dataService.getAllSubCabang().then((res) => {
        const data = res.data.data;
        const value = data.filter((dt) => dt.branch_id == this.newDataEmas.cabang);

        this.selectSubCabang = [{ sub_branch_name: "None", id: null }, ...value];
      });
    },
    async postNewGold() {
      const data = new FormData();

      data.append("name", this.newDataEmas.name);
      data.append("sku", this.newDataEmas.sku);
      data.append("image", this.selectedFile);
      data.append("berat", this.newDataEmas.berat);
      data.append("jenis", this.newDataEmas.jenis);
      data.append("kadar", this.newDataEmas.kadar);
      data.append("kualitas", this.newDataEmas.kualitas);
      data.append("cabang_id", this.newDataEmas.cabang);
      if (this.newDataEmas.subcabang != "") {
        data.append("sub_cabang_id", this.newDataEmas.subcabang);
      }
      data.append("kondisi", this.newDataEmas.kondisi);
      data.append("catatan", this.newDataEmas.catatan);
      data.append("status", this.newDataEmas.status);
      

      await dataService.postNewGold(data).then(() => {
        location.reload();
      });
    },
    closeNewDataEmas() {
      this.$emit("closeNewDataEmas");
    },
    saveNewDataEmas() {
      this.postNewGold();
      this.closeNewDataEmas();
    },
  },
  created() {
    this.getCabang();
    this.getSubCabang();
  },
};
</script>
