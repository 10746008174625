<template>
  <TemplateHeader :header="'Detail Akun'">
    <v-card class="pa-4">
      <div class="d-flex justify-space-between align-center">
        <div class="mb-10">
          <div class="font-weight-medium">Ganti Password</div>
        </div>
      </div>

      <div class="mt-2">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="submit">
            <validation-provider v-slot="{ errors }" name="New Password" rules="required">
              <v-text-field
                label="New Password"
                v-model="newPassword"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="Confirm Password" rules="required">
              <v-text-field
                label="Confirm Password"
                v-model="confirmPassword"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
            <div v-if="errorMessage" class="mt-4 red--text">{{ errorMessage }}</div>
            <div class="w-full d-flex justify-center">
              <v-btn
                @click="changePassword"
                color="myPrimary white--text"
                class="btn mt-4"
                :disabled="invalid"
              >
                {{ loading ? "Loading" : "Submit" }}
              </v-btn>
            </div>
          </form>
        </validation-observer>
      </div>
    </v-card>
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../layouts/TemplateHeader.vue";
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider, setInteractionMode, ValidationObserver } from "vee-validate";
import authServices from "../../services/auth.service";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  components: {
    TemplateHeader,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      EDIT: false,
      newPassword: "",
      confirmPassword: "",
      errorMessage: "",
    };
  },
  methods: {
    async changePassword() {
      this.loading = true;

      const data = {
        password: this.newPassword,
        password_confirmation: this.confirmPassword,
      };

      await authServices
        .changePassword(data)
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          console.log(err);
          this.errorMessage = "Password didn't match , please try again.";
        });

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.btn {
  text-transform: none;
}
</style>
