export const headersCabang = [
  { text: "ID Cabang", value: "branch_id", width: "120px", align: "center" },
  { text: "Nama Cabang", value: "branch_name", width: "170px" },
  {
    text: "Alamat Cabang",
    value: "branch_address",
    sortable: false,
    align: "center",
    width: "300px",
  },
  {
    text: "Nomor Telepon Cabang",
    value: "branch_phone",
    sortable: false,
    align: "center",
    width: "170px",
  },
  {
    text: "Nama Role Cabang",
    value: "role_name",
    sortable: false,
    align: "center",
    width: "170px",
  },
  { text: "Nama User", value: "user_name", sortable: false, align: "center" },
  { text: "Email User", value: "email", sortable: false, align: "center" },
  { text: "Edit / Delete", value: "actions", sortable: false, align: "center" },
];
