<template>
  <TemplateHeader :header="'Berlian Tersedia'" :withBackButton="true" :to="'/dashboard'">
    <TableBerlian
      :tableLoading="tableLoading"
      :dataBerlian="dataBerlian"
      :headersBerlian="headersBerlian"
      :dataPagination="dataPaginationBerlian"
      :sortBy="dataBerlian.id"
      @paginate="getProductsDiamond"
      :tableEdit="true"
    />
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../../layouts/TemplateHeader.vue";
import TableBerlian from "../../../components/Table/TableBerlian.vue";

import { headersBerlian } from "../../../data/headersBerlian";

import dataService from "../../../services/data.service";

export default {
  components: {
    TemplateHeader,
    TableBerlian,
  },
  data() {
    return {
      pagination: {
        per_page: 10,
        page: 1
      },
      dataPaginationBerlian: {},
      tableLoading: false,

      dataBerlian: [],

      headersBerlian: headersBerlian,
    };
  },
  methods: {
    async getProductsDiamond(params) {
      this.tableLoading = true;

      await dataService.getProductsDiamond(params).then((res) => {
        this.dataBerlian = res.data.data.filter(
          (data) =>
            data.status === "Tersedia" ||
            data.status === "Belum Terpindai" ||
            data.status === "Pindah Cabang" ||
            data.status === "Perbaikan"
        );
        this.dataPaginationBerlian = {
          page: res.data.current_page,
          itemsPerPage: parseInt(res.data.per_page),
          pageStart: res.data.from-1,
          pageStop: res.data.to,
          pageCount: res.data.last_page,
          itemsLength: res.data.total,
        }
        this.tableLoading = false;
      });
    },
  },
  created() {
    this.getProductsDiamond(this.pagination);
  },
};
</script>

<style scoped>
.myHeight {
  min-height: 100vh;
}
</style>
