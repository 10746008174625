<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">Edit Data Berlian</span>
    </v-card-title>

    <v-divider></v-divider>

    <validation-observer ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="submit">
        <v-container>
          <v-row class="pa-4">
            <v-col cols="12">
              <validation-provider v-slot="{ errors }" name="RFID" v-if="!PRINT">
                <v-text-field
                  v-model="data.rfid"
                  label="RFID"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3">
              <validation-provider v-slot="{ errors }" name="Kode Barang" rules="required">
                <v-text-field
                  v-model="data.sku"
                  label="Kode Barang"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3">
              <validation-provider v-slot="{ errors }" name="Nama Produk" rules="required">
                <v-text-field
                  v-model="data.name"
                  label="Nama Produk"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3">
              <validation-provider v-slot="{ errors }" name="Kode Harga" rules="required">
                <v-text-field
                  v-model="data.design_number"
                  label="Kode Harga"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3">
              <validation-provider v-slot="{ errors }" name="Berat" rules="required">
                <v-text-field
                  v-model="data.weight"
                  label="Berat"
                  type="number"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3">
              <!-- <validation-provider v-slot="{ errors }" name="Kadar" rules="required"> -->
              <v-text-field v-model="data.purity" label="Kadar" type="number"></v-text-field>
              <!-- </validation-provider> -->
            </v-col>
            <v-col cols="6" sm="3">
              <validation-provider v-slot="{ errors }" name="Harga Modal" rules="required">
                <v-text-field
                  v-model="data.capital_diamond"
                  label="Harga Modal"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3">
              <!-- <validation-provider v-slot="{ errors }" name="Harga" rules="required"> -->
              <v-text-field
                v-model="data.price"
                label="Harga Berlian Terikat"
                type="number"
              ></v-text-field>
              <!-- </validation-provider> -->
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="data.diamond_quantity1"
                label="Jumlah Berlian 1"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field v-model="data.diamond_weight1" label="Ukuran Berlian 1"></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="data.diamond_quantity2"
                label="Jumlah Berlian 2"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field v-model="data.diamond_weight2" label="Ukuran Berlian 2"></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="data.diamond_quantity3"
                label="Jumlah Berlian 3"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field v-model="data.diamond_weight3" label="Ukuran Berlian 3"></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="data.diamond_quantity4"
                label="Jumlah Berlian 4"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field v-model="data.diamond_weight4" label="Ukuran Berlian 4"></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="data.diamond_quantity5"
                label="Jumlah Berlian 5"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field v-model="data.diamond_weight5" label="Ukuran Berlian 5"></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <validation-provider v-slot="{ errors }" name="Cabang" rules="required">
                <v-select
                  v-model="data.branch_id"
                  label="Cabang"
                  :items="selectCabang"
                  item-text="branch_name"
                  item-value="id"
                  :error-messages="errors"
                  @input="getSubCabang"
                >
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3">
              <validation-provider v-slot="{ errors }" name="Sub Cabang">
                <v-select
                  v-model="data.sub_branch_id"
                  label="Sub Cabang"
                  :items="selectSubCabang"
                  item-text="sub_branch_name"
                  item-value="id"
                  :error-messages="errors"
                  :disabled="!data.branch_id"
                >
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3">
              <v-select
                v-model="data.status"
                label="Status"
                :items="selectStatus"
                item-text="label"
                item-value="value"
              >
              </v-select>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field v-model="data.note" label="Catatan"></v-text-field>
            </v-col>

            <v-col cols="6" sm="3" v-if="data.status === 'Terjual'">
              <v-text-field
                v-model="data.total_price"
                :rules="[numberRule]"
                prefix="Rp."
                label="Total Harga"
                :disabled="!data.branch_id"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" v-if="data.status === 'Terjual'">
              <v-text-field
                v-model="data.customer_name"
                label="Nama Pelanggan"
                :disabled="!data.branch_id"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-row v-if="data.status === 'Terjual'" class="px-4">
                <v-col cols="6">
                  <v-time-picker v-model="data.time" ampm-in-title full-width></v-time-picker>
                </v-col>
                <v-col cols="6">
                  <v-date-picker v-model="data.date" full-width></v-date-picker>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" v-if="selectedFile === ''" class="d-flex flex-column align-center">
              <v-img :src="data.photo_product" :alt="data.photo_product" width="250"></v-img>
            </v-col>

            <v-col cols="12" class="d-flex flex-column align-center">
              <v-card class="mt-2 pa-6" max-width="600" v-if="selectedFile" elevation="0">
                <div class="pt-2 py-4 text-center">
                  <img :src="fileImage" width="250" />
                </div>
              </v-card>

              <v-card class="mt-2 pa-6" elevation="0">
                <v-btn
                  block
                  color="myPrimary"
                  outlined
                  depressed
                  :loading="isSelecting"
                  @click="onButtonClick"
                >
                  Ganti Foto
                </v-btn>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="onFileChanged"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions class="d-flex justify-end px-4">
          <v-spacer></v-spacer>
          <v-btn class="myPrimary white--text" outlined @click="closeEdit" width="200">
            Cancel
          </v-btn>
          <v-btn class="myPrimary white--text" @click="saveEdit" :disabled="invalid" width="200">
            Save
          </v-btn>
        </v-card-actions>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";

import dataService from "../../services/data.service";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: ["data", "PRINT"],
  data() {
    return {
      selectedFile: "",
      fileImage: "",
      isSelecting: false,

      selectCabang: [],
      selectSubCabang: [],
      selectStatus: [
        { value: "Tersedia", label: "Tersedia" },
        { value: "Terjual", label: "Terjual" },
        { value: "Perbaikan", label: "Perbaikan" },
        { value: "Hilang", label: "Hilang" },
        { value: "Belum Terpindai", label: "Belum Terpindai" },
      ],
    };
  },
  methods: {
    numberRule(v) {
      if (v != undefined) {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0) return true;
        return "Number must greater than zero (0)";
      } else {
        return true;
      }
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      this.fileImage = URL.createObjectURL(this.selectedFile);

      if (e.target.files.length === 0) {
        this.fileImage = URL.createObjectURL(null);
      }
    },
    async getCabang() {
      await dataService.getCabang().then((res) => {
        this.selectCabang = res.data;
      });
    },
    async getSubCabang() {
      await dataService.getAllSubCabang().then((res) => {
        const data = res.data.data;
        const value = data.filter((dt) => dt.branch_id == this.data.branch_id);

        this.selectSubCabang = [{ sub_branch_name: "None", id: null }, ...value];
      });
    },
    closeEdit() {
      this.$emit("closeEdit");
    },
    saveEdit() {
      this.$emit("saveEdit", this.selectedFile);
    },
  },
  created() {
    this.getCabang();
    this.getSubCabang();
  },
};
</script>
