import LoginView from "../pages/auth/LoginView";

// PUSAT
import DashboardView from "../pages/admin/pusat/DashboardView";
import InventoryView from "../pages/admin/pusat/InventoryView";
import TerjualView from "../pages/admin/pusat/TerjualView";
import BerlianTerjualView from "../pages/admin/pusat/BerlianTerjualView";
import EmasTerjualView from "../pages/admin/pusat/EmasTerjualView";
import EmasTersediaView from "../pages/admin/pusat/EmasTersediaView";
import BerlianTersediaView from "../pages/admin/pusat/BerlianTersediaView";
import CheckoutView from "../pages/admin/pusat/CheckoutView"

// CABANG
import DashboardCabangView from "../pages/admin/cabang/DashboardCabangView";
import TerjualCabangView from "../pages/admin/cabang/TerjualCabangView";
import BerlianTerjualCabangView from "../pages/admin/cabang/BerlianTerjualCabangView";
import EmasTerjualCabangView from "../pages/admin/cabang/EmasTerjualCabangView";
import BerlianTersediaCabangView from "../pages/admin/cabang/BerlianTersediaCabangView";
import EmasTersediaCabangView from "../pages/admin/cabang/EmasTersediaCabangView";

// SUB CABANG
import DashboardSubCabangView from "../pages/admin/sub/DashboardSubCabangView";
import TerjualSubCabangView from "../pages/admin/sub/TerjualSubCabangView";
import BerlianTerjualSubCabangView from "../pages/admin/sub/BerlianTerjualSubCabangView";
import EmasTerjualSubCabangView from "../pages/admin/sub/EmasTerjualSubCabangView";
import BerlianTersediaSubCabangView from "../pages/admin/sub/BerlianTersediaSubCabangView";
import EmasTersediaSubCabangView from "../pages/admin/sub/EmasTersediaSubCabangView";

import PrintView from "../pages/other/PrintView";
import RFIDView from "../pages/other/RFIDView";
import VerificationView from "../pages/other/VerificationView";
import RegisterCabangView from "../pages/other/RegisterCabangView";
import GoldPriceView from "../pages/other/GoldPriceView";
import AccountView from "../pages/other/AccountView";

export default [
  {
    path: "/",
    name: "Login",
    component: LoginView,
    meta: {
      guest: true,
    },
  },
  {
    path: "",
    component: () => import("@/layouts/TemplateMenu"),
    children: [
      // Dashboard Pusat
      {
        path: "/dashboard",
        name: "Dashboard",
        component: DashboardView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/inventory",
        name: "Inventory",
        component: InventoryView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/checkout",
        name: "Checkout",
        component: CheckoutView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/print",
        name: "Print",
        component: PrintView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/rfid",
        name: "RFID",
        component: RFIDView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/gold-price",
        name: "Gold Price",
        component: GoldPriceView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/account",
        name: "Account",
        component: AccountView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/verification",
        name: "Verification",
        component: VerificationView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/new_branch",
        name: "New Branch",
        component: RegisterCabangView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/sold",
        name: "Sold",
        component: TerjualView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/sold-diamond",
        name: "Sold Diamond",
        component: BerlianTerjualView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/sold-gold",
        name: "Sold Gold",
        component: EmasTerjualView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/stock-gold",
        name: "Stock Gold",
        component: EmasTersediaView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/stock-diamond",
        name: "Stock Diamond",
        component: BerlianTersediaView,
        meta: {
          requiresAuth: true,
        },
      },

      // Dashboard Cabang
      {
        path: "/dashboard/:id",
        name: "Dashboard Cabang",
        component: DashboardCabangView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/sold/:id",
        name: "Sold Cabang",
        component: TerjualCabangView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/sold-gold/:id",
        name: "Sold Gold Cabang",
        component: EmasTerjualCabangView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/sold-diamond/:id",
        name: "Sold Diamond Cabang",
        component: BerlianTerjualCabangView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/stock-gold/:id",
        name: "Stock Gold Cabang",
        component: EmasTersediaCabangView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/stock-diamond/:id",
        name: "Stock Diamond Cabang",
        component: BerlianTersediaCabangView,
        meta: {
          requiresAuth: true,
        },
      },

      // Dashboard Sub Cabang
      {
        path: "/dashboard/cabang/:id",
        name: "Dashboard Sub Cabang",
        component: DashboardSubCabangView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/sold/subcabang/:id",
        name: "Sold Sub Cabang",
        component: TerjualSubCabangView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/sold-gold/subcabang/:id",
        name: "Sold Gold Sub Cabang",
        component: EmasTerjualSubCabangView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/sold-diamond/subcabang/:id",
        name: "Sold Diamond Sub Cabang",
        component: BerlianTerjualSubCabangView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/stock-gold/subcabang/:id",
        name: "Stock Gold Sub Cabang",
        component: EmasTersediaSubCabangView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/stock-diamond/subcabang/:id",
        name: "Stock Diamond Sub Cabang",
        component: BerlianTersediaSubCabangView,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
