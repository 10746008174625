<template>
  <div class="myHeight ma-0 myBrokenWhite pa-4">
    <div v-if="withBackButton" class="d-flex justify-space-between">
      <div class="d-flex align-center">
        <v-btn class="btn myPrimary white--text" small @click="$router.go(-1)">
          <v-icon left>mdi-chevron-left</v-icon> Kembali</v-btn
        >
        <div class="text-h5 ml-4">{{ header }} {{ branch_name }}</div>
      </div>
    </div>

    <div v-else class="d-flex justify-space-between">
      <div class="text-h5">{{ header }}</div>
    </div>

    <v-divider class="my-4"></v-divider>

    <slot />
  </div>
</template>

<script>
export default {
  props: ["withBackButton", "header", "branch_name"],
};
</script>

<style scoped>
.myHeight {
  min-height: 100vh;
}
</style>
