<template>
  <v-card elevation="0" :loading="cabangLoading">
    <div class="pa-4">
      <div class="font-weight-medium text-body-1">Penjualan Sub Cabang</div>

      <a
        v-for="subcabang in dataCabang"
        :key="subcabang.id"
        :href="'/dashboard/cabang/' + subcabang.id"
        class="text-decoration-none"
      >
        <v-card class="pa-2 my-4">
          <div class="d-flex align-center py-2">
            <div>
              <v-icon class="blue myPrimary white--text pa-1 rounded-sm" size="15">
                mdi-store-outline
              </v-icon>
            </div>
            <div class="text-body-2 font-weight-medium ml-4">
              {{ subcabang.sub_branch_name }}
            </div>
          </div>
        </v-card>
      </a>
    </div>
  </v-card>
</template>

<script>
import dataService from "../../services/data.service";

export default {
  data() {
    return {
      cabangLoading: false,

      dataCabang: [],
    };
  },
  methods: {
    async getSubCabang() {
      this.cabangLoading = true;
      const id = parseInt(this.$route.params.id);

      await dataService.getSubCabang(id).then((res) => {
        this.dataCabang = res.data.data;
        this.cabangLoading = false;
      });
    },
  },
  created() {
    this.getSubCabang();
  },
};
</script>
