<template>
  <div>
    <v-row>
      <v-col cols="4">
        <div class="pa-2">
          <v-text-field
            v-model="searchCabang"
            append-icon="mdi-magnify"
            label="Pencarian"
            single-line
            hide-details
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="5"></v-col>
      <v-col cols="3">
        <div class="pa-2">
          <v-dialog v-model="dialogNewBranch" max-width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small block color="myPrimary white--text" v-bind="attrs" v-on="on">
                New Branch
                <v-icon right> mdi-plus </v-icon>
              </v-btn>
            </template>
            <BranchModal
              :title="'Tambah Cabang Baru'"
              :NEW="true"
              @closeModal="dialogNewBranch = false"
            />
          </v-dialog>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headersCabang"
      :items="dataCabang"
      :search="searchCabang"
      sort-by="branch_id"
      :loading="tableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-dialog v-model="dialogEdit" max-width="800px">
          <BranchModal
            :title="'Edit Cabang'"
            :EDIT="true"
            :data="showedData"
            @closeModal="closeEdit"
          />
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="560px">
          <StandartModal
            :text="'Apakah anda yakin untuk menghapus Cabang ?'"
            @dialogFalse="dialogDelete = false"
            @dialogTrue="delete_Cabang"
          />
        </v-dialog>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import StandartModal from "../Modal/StandartModal.vue";
import BranchModal from "../Modal/BranchModal.vue";

import { headersCabang } from "../../data/headersCabang";

import dataService from "../../services/data.service";

export default {
  components: {
    BranchModal,
    StandartModal,
  },
  data() {
    return {
      tableLoading: false,

      dialogNewBranch: false,
      dialogEdit: false,
      dialogDelete: false,

      showPass: false,

      deletedDataCabang: {},

      editedIndex: -1,

      dataCabang: [],
      showedData: {},

      searchCabang: "",
      headersCabang: headersCabang,
    };
  },
  watch: {
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.dataCabang.indexOf(item);
      this.showedData = Object.assign({}, item);
      this.dialogEdit = true;
    },

    closeEdit() {
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.showedData = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deletedDataCabang = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    deleteItem(item) {
      this.deletedIndex = this.dataCabang.indexOf(item);
      this.deletedDataCabang = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async getCabang() {
      this.tableLoading = true;

      await dataService.getListCabang().then((res) => {
        this.dataCabang = res.data.data;
        this.tableLoading = false;
      });
    },

    async delete_Cabang() {
      const id = this.deletedDataCabang.branch_id;

      await dataService
        .deleteCabang(id)
        .then(() => {
          location.reload();
        })
        .catch((err) => console.log(err));
    },
  },
  created() {
    this.getCabang();
  },
};
</script>

<style scoped>
.btn {
  text-transform: none;
}
.myHeight {
  min-height: 100vh;
}
</style>
